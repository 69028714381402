.live-view-header {
    background-color: #8CACFD;
    text-align: left;
    color: white;
    font-size: 15px;
    font-weight: bold;
    border: solid 1px #8CACFD;
    padding: 5px;
}

.live-logo {
    border-radius: 50%;
    margin: 1px;
    height: 10px;
    width: 10px;
    transform: scale(1);
    background: rgba(255, 82, 82, 1);
    box-shadow: 0 0 0 0 rgb(255 82 82);
    animation: pulse-red 2s infinite;
    display: inline-block;
}

@keyframes pulse-red {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
    }
    
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
    }
    
    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }
}

.live-view-container th {
    font-size: 12px;
}

.name-row {
  text-align: left;
}
.blog-post-container {
    color: white;
}

.blog-page-header {
    display: flex;
    align-items:center;
    text-align: left;
    padding: 5px;
}

.bloke-container {
    display: inline-block;
}

.bloke-header {
    font-weight: bolder;
    font-size: 30px;
    display: inline-block;
    color: white;
    padding-left:5px;
}

.bloke {
    width: 70px;
    height: 70px;
}

.blog-header {
    font-size: 20px;
    font-weight: bold;
    padding: 5px;
}

.blog-section {
    padding: 5px;
}

.blog-subheader {
    font-size: 15px;
    font-weight: bold;
    padding: 5px;
    border-bottom: 1px solid white;
}

.blog-content {
    font-size: 12px;
    font-weight: bold;
    text-align: left;
    padding: 5px;
}

li {
    display: list-item
}

ul {
    list-style-type: circle;
    list-style-position: inside;
}

.blog-date {
    font-size: 10px;
    text-align: left;
    padding-left: 12px;
}

.league-table th {
    background-color: #8CACFD;
}

.league-table td {
    background-color: white;
    color: #505050;
}

.team-name {
    text-align: left;
}
.pick-container {
    display: flex;
    align-items: center;
    margin: 5px;
    border-bottom: 1px solid darkgray;
    width: 100%;
}

.pick-number-container {
    display: inline-block;
    font-size: 25px;
    border-radius: 25px;
    width: 30px;
    height: 30px;
    text-align: center;
    margin: 5px;
    background-color: #8CACFD;
    color: white;
}

.pick-info-container {
    display: inline-block;
}

.draft-rank-container {
    font-size: 14px;
    color: gray;
}

.no-draft-pick-data {
    background-color: lightgrey;
    color: grey;
    padding: 30px;
    margin: 5px;
}
.twitter-embed-wrapper {
    width: 100%
}

.the-five-blokes-heads {
    width: 20em;
}

.merch {
    width: 300px;
    height: auto;
}

.shop-blokes-title {
    color: white;
    font-weight: bolder;
    font-size: 30px;
    padding: 10px;
}

.blog-link-bloke {
    padding: 10px;
    width: 70px;
    height: 70px;
}

.blokes-blog-title {
    color: white;
    font-weight: bolder;
    font-size: 25px;
    padding: 10px;
}

.bloke-blog-link-container {
    display: inline-block;
    padding: 5px;
}

.blog-main-link {
    color: white;
    font-size: 10px;
}
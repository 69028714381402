:root {
    --gold: #FFCB1F;
    --gold-dark: #F6AE1E;
    --brown: #603814;
    --brown-dark: #3F220F;
    --background-color:#1E253F;
  }
  
  
  body{
    background: var(--background-color);
  }
  
  .box-canvas{
    position: relative;
    margin: auto;
    display: block;
    margin-top: 5vmin;
    width: 60vmin;
    height:60vmin;
  }
  
  .box-canvas::after {
    content: '';
    position: absolute;
    right: 14vmin;
    top: -2vmin;
    width: 7vmin;
    height: 7vmin;
    background: #FFF2C3;
    transform: rotate(20deg);
    clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
    opacity: 0.7;
  }
  
  .handle {
    position: absolute;
    top: 3vmin;
    width: 10vmin;
    height: 20vmin;
    border-radius: 50%;
  }
  
  .handle.left {
    left: 3vmin;
    transform: rotate(-30deg);
    border: 3vmin solid var(--gold);
  }
  
  .handle.right {
    right: 3vmin;
    transform: rotate(30deg);
    border: 3vmin solid var(--gold-dark);
  }
  
  .cup {
    position: absolute;
    top: 1vmin;
    left: 50%;
    transform: translateX(-50%);
    width: 35vmin;
    height: 35vmin;
    background: var(--gold);
    border-radius: 0 0 50% 50%;
    text-align: center;
  }
  
  .lip {
    position: absolute;
    top: -1vmin;
    height: 2vmin;
    width: 39vmin;
    left: -2vmin;
    background: linear-gradient(to left,
    var(--gold-dark),
    var(--gold-dark) 6vmin,
    var(--gold) 6vmin
    );
    border-radius: 10% / 50%;
  }
  
  /* Shine */
  .cup::before {
    content: '';
    position: absolute;
    left: 5vmin;
    top: 2vmin;
    width: 25vmin;
    height: 28vmin;
    border-radius: 0 0 50% 50%;
    box-shadow: -2vmin 0 0 #FFF2C3;
  }
  
  .trophy-bottom {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 35vmin;
    width: 20vmin;
    height: 12vmin;
    background: var(--gold);
  }
  
  .cutout.left {
    position: absolute;
    left: -2vmin;
    width: 8vmin;
    height: 8vmin;
    background: var(--background-color);;
    border-radius: 0 0 50% 0;
    transform: skew(-20deg);
  }
  
  .cutout.left::before {
    content: '';
    position: absolute;
    width: 8vmin;
    height: 7vmin;
    top: 2vmin;
    box-shadow: 1vmin 0 0 #FFF2C3;
    border-radius: 0 0 50% 0;
    left: 1vmin;
  }
  
  .trophy-bottom::before {
    content: '';
    position: absolute;
    right: -2vmin;
    width: 8vmin;
    height: 8vmin;
    background: var(--background-color);
    border-radius: 0 0 0 50%;
    transform: skew(20deg);
    box-shadow: -2vmin 0 0 var(--gold-dark);
  }
  
  .trophy-bottom::after {
    content: '';
    position: absolute;
    width: 25vmin;
    height: 2vmin;
    background: var(--gold-dark);
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
  }
  
  .stand {
    position: absolute;
    bottom: 3vmin;
    left: 50%;
    transform: translateX(-50%);
    width: 30vmin;
    height: 10vmin;
    background: var(--brown);
  }
  
  .stand::before {
    content: '';
    position: absolute;
    bottom: -2vmin;
    left: 50%;
    transform: translateX(-50%);
    height: 2vmin;
    width: 34vmin;
    background: var(--brown-dark);
  }
  
  .plaque {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 16vmin;
    height: 6vmin;
    background: var(--gold);
    background: linear-gradient(45deg,
      #FFF2C3, 
      #FFF2C3 40%, 
      var(--gold) 40%
    );
    color: var(--gold-dark);
    font-family: Arial, sans-serif;
    font-size: 4.5vmin;
    padding-top: 0.75vmin;
    text-align: center;
  }
  
  .screws {
    position: absolute;
    width: 14vmin;
    height: 2vmin;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .screws.top {
    top: 0.5vmin;
  }
  
  .screws.bottom {
    bottom: 0.5vmin;
  }
  
  .screws::before {
    content: '';
    position: absolute;
    left: 0;
    width: 1.5vmin;
    height: 1.5vmin;
    background: var(--gold-dark);
    border-radius: 50%;
  }
  
  .screws::after {
    content: '';
    position: absolute;
    right: 0;
    width: 1.5vmin;
    height: 1.5vmin;
    background: var(--gold-dark);
    border-radius: 50%;
  }

.engraved-text {
    color: var(--gold-dark);
    position: relative;
    top: 15px;
}

.champion-engraving {
    font-size: 25px;
}

.team-engraving {
    font-size: 15px;
}